<template>
    <div class="home">
        <div class="head-card">
            <div class="head-card-title">先用后付</div>
            <div class="head-card-tag">限时开放</div>
            <div class="head-card-content">
                <div class="head-card-content-title">预估可领（元）</div>
                <div class="head-card-content-num">20000</div>
                <div class="head-card-content-btn" @click="$router.push({ name: 'enjoy' })">立即领取</div>
            </div>
        </div>
        <div class="head-gitf">
            <div class="head-gitf-title">
                <div class="head-gitf-title-msg">礼包购</div>
                <div class="head-gitf-title-icon"><img src="@/assets/recommend.png" alt="" /></div>
            </div>
            <div class="head-gitf-title2">尊敬的用户，保持良好的信誉即可提额</div>
            <div class="head-Ecard">
                <div class="image">
                    <img src="@/assets/Ecard.png" alt="" />
                </div>
                <div class="head-Ecard-content">
                    <div>京东E卡</div>
                    <div class="head-Ecard-content-msg">电子卡 | 传递真情</div>
                    <div class="head-Ecard-content-tag">
                        <div class="head-Ecard-content-tag-item">更优惠</div>
                        <div class="head-Ecard-content-tag-item">可使用分期额度</div>
                    </div>
                </div>
                <div class="head-Ecard-btn" @click="$router.push({ name: 'enjoy' })">去查看</div>
            </div>
        </div>
        <div class="head-tips">
            <div class="head-tips-icon">提醒</div>
            <div class="head-tips-content">
                您有一笔
                <span class="head-tips-content-red">先用后付</span>
                金待领
            </div>
            <div class="head-tips-pic">
                <img src="@/assets/receive.png" @click="$router.push({ name: 'enjoy' })" alt="" />
            </div>
        </div>
        <div class="heag-tag">
            <div class="heag-tag-item" @click="showType = 1">
                <div class="heag-tag-item-title">本周热卖</div>
                <span :class="{ 'heag-tag-item-msg': true, 'msg-active': showType == 1 }">本周热卖 方便快捷</span>
            </div>
            <div class="heag-tag-item" @click="showType = 2">
                <div class="heag-tag-item-title">福利好物</div>
                <span :class="{ 'heag-tag-item-msg': true, 'msg-active': showType == 2 }">旗舰品牌 精致必备</span>
            </div>
            <div class="heag-tag-item" @click="showType = 3">
                <div class="heag-tag-item-title">热销榜单</div>
                <span :class="{ 'heag-tag-item-msg': true, 'msg-active': showType == 3 }">排行榜单</span>
            </div>
        </div>
        <div class="item-list">
            <div class="item" v-for="item in showList" :key="item.id" @click="$router.push({ name: 'DetailItem', query: { id: item.id, type: 'home' } })">
                <div class="item-pic">
                    <img :src="item.image" alt="" />
                </div>
                <div class="item-msg">{{ item.title }}</div>
                <div class="item-price">{{ item.money }}</div>
            </div>
            <!-- <div class="item">
                <div class="item-pic">
                    <img src="@/assets/01667a71ea48408289c0e4e52619a3a9-d75bad14.jpg" alt="" />
                </div>
                <div class="item-msg">随心用（5999京东e...</div>
                <div class="item-price">5999.00</div>
            </div> -->
        </div>
        <div class="home-num">浙ICP备2024105868号-1</div>
        <div class="home-footer">
            <div class="home-footer-item">
                <img class="home-footer-item-pic" src="@/assets/home-icon-active.png" alt="" />
                <span class="home-footer-item-title red">首页</span>
            </div>
            <div class="home-footer-item" @click="$router.push({ name: 'my' })">
                <img class="home-footer-item-pic" src="@/assets/me.png" alt="" />
                <span class="home-footer-item-title">我的</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { tuijianShopApi } from "@/api/shopApi";
import { useRouter } from "vue-router";
import { ref, watch } from "vue";
// const router = useRouter();
const showType = ref(1);
const showList = ref([]);
// const route = useRoute();
(async () => {
    const res = await tuijianShopApi(showType.value);
    console.log(res);
    showList.value = res?.data?.list || [];
})();
watch(showType, async newVal => {
    const res = await tuijianShopApi(newVal);

    console.log(res.data.list);
    showList.value = res.data.list || [];
    // const res = await ordersListApi({ status: newVal });
    // console.log(res.data, 999);
});
</script>
<style lang="scss" scoped>
.home {
    padding: 30px;
    background-color: #f6f6f6;
    .head-card {
        // height: 13.375rem;
        width: 690px;
        height: 428px;
        background: url("@/assets/bg-7fbdb018-7fbdb018.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .head-card-title {
            font-size: 36px;
            font-weight: 500;
            color: #fff;
            position: absolute;
            top: 30px;
            left: 40px;
        }
        .head-card-tag {
            font-size: 26px;
            font-weight: 500;
            color: #fff;
            position: absolute;
            top: 5px;
            right: 30px;
        }
        .head-card-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: absolute;
            width: 100%;
            top: 127.33px;
            // margin-top: ;
            .head-card-content-title {
                font-size: 26px;
                font-weight: 500;
            }
            .head-card-content-num {
                font-size: 80px;
                font-weight: 500;
                padding-top: 10px;
                padding-bottom: 20px;
            }
            .head-card-content-btn {
                width: 194px;
                height: 40px;
                background-color: #fff;
                border-radius: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #ff6545;
            }
        }
    }
    .head-gitf {
        width: 610px;
        height: 255px;
        padding: 28px 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        background-color: rgba(255, 115, 76, 0.1);
        border-radius: 0.9375rem;
        .head-gitf-title {
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .head-gitf-title-msg {
                font-size: 16px;
            }
            .head-gitf-title-icon {
                margin-left: 20px;
                width: 184;
                height: 33px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .head-gitf-title2 {
            color: #908e8f;
            font-size: 24px;
            padding-top: 10px;
            padding-bottom: 20px;
            width: 100%;
            height: 32px;
            text-align: left;
        }
        .head-Ecard {
            font-size: 16px;
            height: 160px;
            background-color: #fff;
            border-radius: 30px;
            display: flex;
            align-items: center;
            .image {
                margin-right: 20px;
                height: 38px;
                width: 59px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .head-Ecard-content {
                display: flex;
                flex-direction: column;
                font-size: 24px;
                // align-items: left;
                text-align: left;
                flex: 1;
                // justify-content: center;
                .head-Ecard-content-msg {
                    font-size: 24px;
                    color: #908e8f;
                    margin-top: 5px;
                    margin-bottom: 10px;
                }
                .head-Ecard-content-tag {
                    font-size: 20px;
                    display: flex;
                    .head-Ecard-content-tag-item {
                        padding: 5px 10px;
                        background-color: #f6d3d2;
                        color: #f82035;
                        margin-right: 20px;
                        border-radius: 6px;
                    }
                    // .head-Ecard-content-tag-item2{}
                }
            }
            .head-Ecard-btn {
                padding: 10px 20px;
                background-color: #fcdd44;
                border-radius: 40px;
                font-size: 26px;
                color: #fff;
                margin-right: 4px;
            }
        }
    }
    .head-tips {
        margin-top: 60px;
        padding: 20px 30px;
        display: flex;
        // justify-content: center;
        align-items: center;
        border-radius: 30px;
        background-color: #fff;
        position: relative;
        .head-tips-icon {
            font-size: 24px;
            padding: 5px 10px;
            background-color: #fcdd44;
            // font-size: .75rem;
            border-radius: 4px;
        }
        .head-tips-content {
            font-size: 26px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            flex: 1;
            .head-tips-content-red {
                color: #f82035;
                font-weight: 500;
            }
        }
        .head-tips-pic {
            position: absolute;
            width: 152px;
            height: 115px;
            right: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .heag-tag {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
        .heag-tag-item {
            flex: 1;
            .heag-tag-item-title {
                font-size: 28px;
                color: #000;
            }
            .heag-tag-item-msg {
                color: #666;
                font-size: 20px;
                padding: 5px 13px;
                border-radius: 20px;
            }
            .msg-active {
                background-color: #fa8c16;
                font-weight: 500;
                color: #fff;
            }
        }
    }
    .item-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: 291px;
            // height: 370px;
            padding: 20px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: flex-start;
            background-color: #fff;
            border-radius: 15px;
            .item-pic {
                width: 250px;
                height: 250px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .item-msg {
                width: 100%;
                margin-top: 20px;
                color: #303133;
                flex: 1;
                font-size: 32px;
                text-align: left;
            }
            .item-price {
                width: 100%;
                text-align: left;
                color: #ff5733;
                font-size: 32px;
            }
        }
    }
    .home-num {
        margin-bottom: 100px;
        color: rgb(204, 204, 204);
        font-size: 32px;
    }
    .home-footer {
        width: 100%;
        height: 100px;
        display: flex;
        position: fixed;
        z-index: 999;
        bottom: 0;
        left: 0;

        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-top: 1px solid rgba(171, 171, 171, 0.3);
        .home-footer-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .home-footer-item-pic {
                width: 48px;
                height: 48px;
            }
            .home-footer-item-title {
                font-size: 16px;

                color: rgb(122, 126, 131);

                font-size: 20px;
                // line-height: normal;
                margin-top: 6px;
            }
            .home-footer-item-title.red {
                color: rgb(248, 32, 53);
            }
        }
        // font-size: 16px;
        // color: #666;
    }
}
</style>
