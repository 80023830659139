import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { title: "首页" },
    },
    {
        path: "/my",
        name: "my",
        component: () => import(/* webpackChunkName: "about" */ "../views/myView.vue"),
        meta: { title: "我的" },
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
        meta: { title: "登录" },
    },
    {
        path: "/enjoy",
        name: "enjoy",
        component: () => import(/* webpackChunkName: "about" */ "../views/EnjoyView.vue"),
        meta: { title: "享购商品" },
    },
    {
        path: "/face",
        name: "face",
        component: () => import(/* webpackChunkName: "about" */ "../views/faceView.vue"),
        meta: { title: "实名认证" },
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import(/* webpackChunkName: "about" */ "../views/contactsView.vue"),
        meta: { title: "绑定联系人" },
    },
    {
        path: "/addAddress",
        name: "addAddress",
        component: () => import(/* webpackChunkName: "about" */ "../views/addAddress.vue"),
        meta: { title: "设置地址" },
    },
    {
        path: "/addressPage",
        name: "addressPage",
        component: () => import(/* webpackChunkName: "about" */ "../views/addressView.vue"),
        meta: { title: "设置地址" },
    },
    {
        path: "/DetailItem",
        name: "DetailItem",
        component: () => import(/* webpackChunkName: "about" */ "../views/DetailItem.vue"),
        meta: { title: "商品详情" },
    },
    {
        path: "/StatusList",
        name: "StatusList",
        component: () => import(/* webpackChunkName: "about" */ "../views/StatusList.vue"),
        meta: { title: "订单列表" },
    },
    {
        path: "/statusDetail",
        name: "statusDetail",
        component: () => import(/* webpackChunkName: "about" */ "../views/statusDetail.vue"),
        meta: { title: "订单详情" },
    },
    {
        path: "/OrderView",
        name: "OrderView",
        component: () => import(/* webpackChunkName: "about" */ "../views/OrderView.vue"),
        meta: { title: "确认订单" },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title; // 设置页面标题
    }
    next();
});
export default router;
