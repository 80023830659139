import axios from "axios";
import router from "@/router/index.js";
import { ElMessage } from "element-plus";

const request = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});
// 请求拦截器
request.interceptors.request.use(
    config => {
        const configT = config;
        if (localStorage.getItem("userInfo")) {
            configT.headers.Token = JSON.parse(localStorage.getItem("userInfo"))?.token;
            // configT.headers['ngrok-skip-browser-warning'] = 60940;
        }
        return configT;
    },
    error => {
        // 请求错误处理
        console.log("Request Error:", error);
        return Promise.reject(error);
    }
);
// 添加响应拦截器
request.interceptors.response.use(
    response => {

        const res = response;
        // console.log(res.data.code, 89898);
        if (res.data.code == 201 || res.data.msg == "请登录后操作") {
            // location.href = location.origin + "/#/login";
            router.push({ name: "login" });
        } else if (res.data.code !== 1) {
            console.log("执行");

            // ElMessage.error(res.data.msg);
        }

        return res.data;
    },
    error => {
        // ElMessage.error(error.response.data.message);
        return Promise.reject(error);
    }
);
export default request;
